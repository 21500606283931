import React from 'react';

import Cart from '../components/Cart';
import SEO from '../components/SEO';
import FirebaseLayout from '../components/Firebase/FirebaseLayout';
import FullScreenContainer from '../components/FullScreenContainer';

import Layout from '../components/Layout';
import BackToShopLink from '../components/BackToShopLink';

function CartPage() {
  return (
    <Layout>
      <FirebaseLayout>
        <SEO pageTitle="Koszyk" />

        <BackToShopLink />
        <div className="pt-5 mb-6 px-6">
          {/* <div className="mb-6"> */}
          <h1 className="font-bold text-3xl md:text-6xl mb-3 text-white">
            Koszyk
          </h1>

          <hr className="border-b border-gainsboro w-10" />
          {/* </div> */}
        </div>

        <Cart />
      </FirebaseLayout>
    </Layout>
  );
}

export default CartPage;
