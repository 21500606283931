import React from 'react';

import PotockLink from './PotockLink';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

// Main Component
const BackToShopLink = ({ className }) => {
  return (
    <div className={'px-6 pt-20 text-white ' + (className ? className : '')}>
      <PotockLink
        className="text-white border-b-2 border-transparent hover:border-white transition ease-in duration-200"
        to="https://sklep.potock.pl"
      >
        <FontAwesomeIcon icon={faLongArrowAltLeft} /> wróć do sklepu
      </PotockLink>
    </div>
  );
};

export default BackToShopLink;
