import React from 'react';
import { Link } from 'gatsby';
import { useCart } from 'react-use-cart';

import CartItemList from './CartItemList';

import PotockLink from '../components/PotockLink';

function Cart() {
  const cart = useCart();
  const { isEmpty, cartTotal } = cart;

  const formattedSubTotal = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  }).format(cartTotal);

  if (isEmpty)
    return (
      <div className="bg-white p-6 mt-6 mx-6 rounded">
        <p className="">Twój koszyk jest pusty</p>
      </div>
    );

  return (
    <div className="px-6">
      <CartItemList />

      <div className="mt-3 md:mt-6 py-3 md:py-6 border-t-2 border-gainsboro">
        <div className="flex flex-col items-end">
          <div className="flex flex-col items-end mb-3">
            <span className="text-white">Suma</span>
            <span className="text-xl font-bold text-white">
              {formattedSubTotal}
            </span>
          </div>

          <PotockLink
            to="/zamowienie"
            className="bg-success hover:bg-successhover px-4 py-3 rounded-lg text-white text-sm font-bold tracking-widest uppercase focus:outline-none"
          >
            Zamów
          </PotockLink>
        </div>
      </div>
    </div>
  );
}

export default Cart;
